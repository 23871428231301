import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { BackArrowIcon } from "./BackArrowIcon";
import { CloseIcon } from "./CloseIcon";
import { ForwardArrowIcon } from "./ForwardArrowIcon";
import "./PortfolioCategoryImageView.scss";

export function ImageView({ portfolioCategory, match, history }) {
  const imageId = match.params.imageId;
  const categoryUrl = `/portfolio/category/${portfolioCategory.id}`;

  const goToPreviousImage = useCallback(() => {
    const previousImageId = imageId > 0 ? imageId - 1 : portfolioCategory.images.length - 1;
    const previousImageUrl = `${categoryUrl}/image/${previousImageId}`;

    history.push(previousImageUrl);
  }, [categoryUrl, imageId, history, portfolioCategory.images.length]);

  const goToNextImage = useCallback(() => {
    const nextImageId = imageId < portfolioCategory.images.length - 1 ? +imageId + 1 : 0;
    const nextImageUrl = `${categoryUrl}/image/${nextImageId}`;
    history.push(nextImageUrl);
  }, [categoryUrl, imageId, history, portfolioCategory.images.length]);

  const close = useCallback(() => {
    history.push(categoryUrl);
  }, [categoryUrl, history]);

  const onKeyDown = useCallback(
    e => {
      console.log(e.keyCode);
      switch (e.keyCode) {
        case 37: // left-arrow
          goToPreviousImage();
          break;
        case 39: // right-arrow
          goToNextImage();
          break;
        case 27: // escape
          close();
          break;
        default:
      }
    },
    [close, goToPreviousImage, goToNextImage]
  );
  useEffect(() => {
    document.body.addEventListener("keydown", onKeyDown);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
      document.body.style.overflow = "auto";
    };
  }, [onKeyDown]);
  return ReactDOM.createPortal(
    <div className="portfolio-category-image-view">
      <div className="close-button" onClick={close}>
        <CloseIcon />
      </div>
      <div className="image-container">
        <div className="previous-image-button" onClick={goToPreviousImage}>
          <BackArrowIcon />
        </div>
        <img className="image" src={portfolioCategory.images[imageId]} alt={portfolioCategory.name} />
        <div className="next-image-button" onClick={goToNextImage}>
          <ForwardArrowIcon />
        </div>
      </div>
    </div>,
    document.body
  );
}
