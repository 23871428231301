import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { actionTypes } from "./actions";

const initialState = {
  homePageImages: [],
  aboutCompany: {}
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.BASIC_DATA_FETCHED:
      return {
        ...state,
        homePageImages: action.images,
        companyContact: action.companyContact,
        socialMediaLinks: action.socialMediaLinks,
        basicDataFetched: true
      };
    case actionTypes.ABOUT_COMPANY_FETCHED:
      return {
        ...state,
        aboutCompany: action.aboutCompany
      };
    case actionTypes.GOOGLE_MAPS_LOCATION_FETCHED:
      return {
        ...state,
        googleMapsLocation: action.googleMapsLocation
      };
    case actionTypes.PORTFOLIO_FETCHED:
      return {
        ...state,
        portfolio: action.portfolio
      };
    case actionTypes.PORTFOLIO_CATEGORY_FETCHED:
      return {
        ...state,
        portfolioCategories: {
          ...state.portfolioCategories,
          [action.portfolioCategoryId]: action.portfolioCategory
        }
      };
    default:
      return state;
  }
}

export const store = createStore(rootReducer, applyMiddleware(thunk));
