import GoogleMapReact from "google-map-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ContactPage.scss";
import { fetchGoogleMapsLocation } from "../../store/actions";

export function ContactPage() {
  const dispatch = useDispatch();
  const { googleMapsLocation, companyContact } = useSelector(state => ({
    googleMapsLocation: state.googleMapsLocation,
    companyContact: state.companyContact
  }));
  useEffect(() => {
    dispatch(fetchGoogleMapsLocation());
  }, [dispatch]);
  const renderMarkers = (map, maps) => {
    new maps.Marker({
      position: googleMapsLocation.center,
      map: map
    });
  };
  return (
    <div className="contact-page">
      <div className="content">
        <div className="google-map-wrapper">
          {googleMapsLocation && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyB-yX3dlZSsqMag4GYJu689pf1WTksxtoA" }}
              defaultCenter={googleMapsLocation.center}
              defaultZoom={googleMapsLocation.zoom}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            />
          )}
        </div>
        <div className="company-contact">
          <h1 className="name">{companyContact.name}</h1>
          <h3 className="nip">{companyContact.nip}</h3>
          <h3 className="address">
            <span>{companyContact.city}</span>, <span>{companyContact.street}</span>
          </h3>
          <h3 className="phone">{companyContact.phone}</h3>
        </div>
      </div>
    </div>
  );
}
