import React from "react";
import { useSelector } from "react-redux";
import "./Footer.scss";
import { SocialIcon } from "react-social-icons";

export function Footer() {
  const { companyContact, socialMediaLinks } = useSelector(state => ({
    companyContact: state.companyContact,
    socialMediaLinks: state.socialMediaLinks
  }));
  return (
    <div className="footer">
      <div className="content">
        <div className="social-media-links">
          <div className="first-row">
            <SocialIcon url={socialMediaLinks.instagram} />
            <SocialIcon url={socialMediaLinks.facebook} />
          </div>
          <div>
            <SocialIcon url={socialMediaLinks.email} />
          </div>
        </div>
        <div className="company-contact">
          <div>{companyContact.name}</div>
          <div>{companyContact.nip}</div>
          <div>
            <span>{companyContact.city}</span>, <span>{companyContact.street}</span>
          </div>
          <div>{companyContact.phone}</div>
        </div>
      </div>
    </div>
  );
}
