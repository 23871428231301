import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route } from "react-router-dom";
import { fetchPortfolio, fetchPortfolioCategory } from "../../store/actions";
import { ImageView } from "./PortfolioCategoryImageView";
import "./PortfolioCategoryPage.scss";
import { BackArrowIcon } from "./BackArrowIcon";

export function PortfolioCategoryPage({ match, history }) {
  const portfolioCategoryId = match.params.id;
  const dispatch = useDispatch();
  const portfolioCategory = useSelector(
    state =>
      state.portfolio &&
      state.portfolioCategories && {
        ...state.portfolioCategories[portfolioCategoryId],
        name: state.portfolio.categories.find(category => category.id === +portfolioCategoryId).name
      }
  );

  const goBackToCategories = () => {
    history.push("/portfolio");
  };

  useEffect(() => {
    dispatch(fetchPortfolio());
    dispatch(fetchPortfolioCategory(match.params.id));
  }, [dispatch, match.params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.id]);
  return (
    <div className="portfolio-category-page">
      {portfolioCategory && portfolioCategory.images && (
        <div className="content">
          <h1 className="category-name">
            <BackArrowIcon className="go-back-to-categories-button" onClick={goBackToCategories} />
            {portfolioCategory.name}
          </h1>
          <div className="images-grid">
            {portfolioCategory.images.map((image, index) => (
              <Link key={image} to={`/portfolio/category/${portfolioCategory.id}/image/${index}`}>
                <img className="image" src={image} alt="Portfolio category" />
              </Link>
            ))}
          </div>
          <Route
            path={`/portfolio/category/${portfolioCategory.id}/image/:imageId`}
            render={props => <ImageView {...props} portfolioCategory={portfolioCategory} />}
          />
        </div>
      )}
    </div>
  );
}
