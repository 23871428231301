import { api } from "../api";

export const actionTypes = {
  BASIC_DATA_FETCHED: "BASIC_DATA_FETCHED",
  ABOUT_COMPANY_FETCHED: "ABOUT_COMPANY_FETCHED",
  GOOGLE_MAPS_LOCATION_FETCHED: "GOOGLE_MAPS_LOCATION_FETCHED",
  PORTFOLIO_FETCHED: "PORTFOLIO_FETCHED",
  PORTFOLIO_CATEGORY_FETCHED: "PORTFOLIO_CATEGORY_FETCHED"
};

export function fetchBasicData() {
  return async dispatch => {
    const { images } = await api.fetchHomePageImages();
    const companyContact = await api.fetchCompanyContact();
    const socialMediaLinks = await api.fetchSocialMediaLinks();

    dispatch({
      type: actionTypes.BASIC_DATA_FETCHED,
      images,
      companyContact,
      socialMediaLinks
    });
  };
}

export function fetchAboutCompany() {
  return async (dispatch, getState) => {
    const currentAboutCompany = getState().aboutCompany;
    if (!(currentAboutCompany.image && currentAboutCompany.text)) {
      const aboutCompany = await api.fetchAboutCompany();
      dispatch({
        type: actionTypes.ABOUT_COMPANY_FETCHED,
        aboutCompany
      });
    }
  };
}

export function fetchGoogleMapsLocation() {
  return async (dispatch, getState) => {
    if (!getState().googleMapsLocation) {
      const googleMapsLocation = await api.fetchGoogleMapsLocation();
      dispatch({
        type: actionTypes.GOOGLE_MAPS_LOCATION_FETCHED,
        googleMapsLocation
      });
    }
  };
}

export function fetchPortfolio() {
  return async (dispatch, getState) => {
    if (!getState().portfolio) {
      const portfolio = await api.fetchPortfolio();
      dispatch({
        type: actionTypes.PORTFOLIO_FETCHED,
        portfolio
      });
    }
  };
}

export function fetchPortfolioCategory(categoryId) {
  return async dispatch => {
    const portfolioCategory = await api.fetchPortfolioCategory(categoryId);
    dispatch({
      type: actionTypes.PORTFOLIO_CATEGORY_FETCHED,
      portfolioCategoryId: categoryId,
      portfolioCategory: {
        ...portfolioCategory,
        id: categoryId
      }
    });
  };
}
