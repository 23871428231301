import React from "react";
import { useSelector } from "react-redux";
import "./HomePage.scss";
import Slideshow from "./Slideshow";

export function HomePage() {
  const images = useSelector(state => state.homePageImages);
  return (
    <div className="home-page">
      <Slideshow images={images} />
    </div>
  );
}
