import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PortfolioPage.scss";
import { fetchPortfolio } from "../../store/actions";
import { Link } from "react-router-dom";

export function PortfolioPage() {
  const dispatch = useDispatch();
  const portfolio = useSelector(state => state.portfolio);
  useEffect(() => {
    dispatch(fetchPortfolio());
  }, [dispatch]);
  return (
    <div className="portfolio-page">
      {portfolio && (
        <div className="content">
          <div className="categories-grid">
            {portfolio.categories.map(category => (
              <Link key={category.id} to={`/portfolio/category/${category.id}`}>
                <div className="category-card">
                  <img className="image" src={category.image} alt={category.name} />
                  <h3 className="name">{category.name}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
