import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo.png";

export function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleMenu = () => setDrawerOpen(open => !open);
  return (
    <div className="header">
      <div className="company-logo">
        <button className="menu-button" onClick={toggleMenu} />
        <Link className="company-logo" to="/">
          <img src={CompanyLogo} alt="company-logo" />
        </Link>
      </div>
      <div className="links">
        <Link to="/company">O firmie</Link>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/contact">Kontakt</Link>
      </div>
      <nav className={`drawer ${drawerOpen ? "open" : ""}`}>
        <Link to="/company" onClick={toggleMenu}>
          O firmie
        </Link>
        <Link to="/portfolio" onClick={toggleMenu}>
          Portfolio
        </Link>
        <Link to="/contact" onClick={toggleMenu}>
          Kontakt
        </Link>
      </nav>
    </div>
  );
}
