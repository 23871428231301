export const api = {
  async fetchHomePageImages() {
    const response = await fetch("http://18.184.195.136/api/v1/start/");
    return await response.json();
  },
  async fetchCompanyContact() {
    const response = await fetch("http://18.184.195.136/api/v1/contact/");
    return await response.json();
  },
  async fetchSocialMediaLinks() {
    const response = await fetch("http://18.184.195.136/api/v1/social/");
    return await response.json();
  },
  async fetchAboutCompany() {
    const response = await fetch("http://18.184.195.136/api/v1/about/");
    return await response.json();
  },
  async fetchGoogleMapsLocation() {
    const response = await fetch("http://18.184.195.136/api/v1/location/");
    const { google_location: locationUrl } = await response.json();
    const urlPattern = /.*@(.*),(.*),(.*)z/;
    const match = locationUrl.match(urlPattern);
    return {
      center: {
        lat: +match[1],
        lng: +match[2]
      },
      zoom: +match[3]
    };
  },
  async fetchPortfolio() {
    const response = await fetch("http://18.184.195.136/api/v1/portfolio/");
    return await response.json();
  },
  async fetchPortfolioCategory(categoryId) {
    const response = await fetch(`http://18.184.195.136/api/v1/portfolio/${categoryId}/`);
    return await response.json();
  }
};
