import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { CompanyPage } from "./pages/company/CompanyPage";
import { ContactPage } from "./pages/contact/ContactPage";
import { HomePage } from "./pages/home/HomePage";
import { PortfolioPage } from "./pages/portfolio/PortfolioPage";
import { PortfolioCategoryPage } from "./pages/portfolio-category/PortfolioCategoryPage";
import { fetchBasicData } from "./store/actions";
import "./styles/breakpoints.scss";
import "./styles/theme.scss";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBasicData());
  }, [dispatch]);
  const basicDataFetched = useSelector(state => state.basicDataFetched);
  if (!basicDataFetched) {
    return <div />;
  }
  return (
    <>
      <Header />
      <main className="content-wrapper">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/company" component={CompanyPage} />
          <Route exact path="/portfolio" component={PortfolioPage} />
          <Route path="/portfolio/category/:id" component={PortfolioCategoryPage} />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

export default App;
