import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CompanyPage.scss";
import { fetchAboutCompany } from "../../store/actions";

export function CompanyPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAboutCompany());
  }, [dispatch]);
  const { image, text } = useSelector(state => state.aboutCompany);
  return (
    <div className="company-page">
      <div>
        <p className="text">{text}</p>
        <img className="image" src={image} alt="Company" />
      </div>
    </div>
  );
}
