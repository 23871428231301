import React from "react";
import "./Slideshow.scss";

export default function Slideshow({ images }) {
  return (
    <div className="slider">
      <div className="images">
        {[...images, images[0]].map((image, index) => (
          <div key={index} style={{ backgroundImage: `url(${image})` }} />
        ))}
      </div>
    </div>
  );
}
